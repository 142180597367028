import {
    Outlet,
    useNavigate
} from 'react-router-dom';


import {
    FlexiSignInButton,
    PlatformAppShell,
    PlatformFooter,
    PlatformHeader,
    PlatformHeaderNavigationMenu,
    PlatformHeaderToolbar,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Suspense} from "react";
import config from "../config";
import {signInWithRedirect} from 'aws-amplify/auth';

export const PublicLayout = () => {
    const navigate = useNavigate()


    const menuItems = [{
        title: 'Home',
        onClick: () => navigate('/')
    }, {
        title: 'Products',
        onClick: () => navigate('/products')
    }, {
        title: 'Pricing',
        onClick: () => navigate('/pricing')
    }, {
        title: 'About',
        onClick: () => navigate('/about')
    }]


    const additionalToolbarItems = [
        <FlexiSignInButton onClick={
            () => {
                signInWithRedirect()
            }
        }>Sign In</FlexiSignInButton>,
    ]

    const showLoginButton = false
    if (showLoginButton) {
        additionalToolbarItems.push(<FlexiSignInButton
            onClick={() => window.location.assign(config.platform.portal + '/login?returnTo=' +
                                                      config.platform.domains
            )}>Login</FlexiSignInButton>)
    }

    const toolbar = <PlatformHeaderToolbar additionalToolbarItems={additionalToolbarItems}/>

    const header = <PlatformHeader
        navigationMenu={<PlatformHeaderNavigationMenu menuItems={menuItems}/>}
        toolbar={toolbar}
    />

    return <PlatformAppShell
        platformHeader={header}
        platformFooter={<PlatformFooter/>}
    >
        <Suspense fallback={<PlatformLoader/>}>
            <Outlet/>
        </Suspense>
    </PlatformAppShell>


};
