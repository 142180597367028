import {I18n} from '@aws-amplify/core';

import {FlexiAddButton,} from '@flexinet/ui-components';

import {useNavigate} from 'react-router-dom';

export const UploadButton = ({
                                 buttonText = 'Upload',
                                 onClick,
                             }: {
    buttonText?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('/domain-names/upload');
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};