import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useDomainNames} from '../hooks/useDomainNames';
import {useDomainNamesSelection} from "../hooks/useDomainNamesSelection.ts";

export const DeleteRecordButton = () => {

    const {deleteRecords} = useDomainNames();

    const {
        selection,
        handleClearSelection,
    } = useDomainNamesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};
